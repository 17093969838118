<div class="authorized-button-modal__header">
  Market Guru - Официальный авторизованный сервис Wildberries
  <i (click)="dialogRef.close()" class="mg-icon-close"></i>
</div>
<picture>
  <source srcset="/assets/images/home/authorized-service.webp" type="image/webp">
  <img alt="Wildberries" src="/assets/images/home/authorized-service.png">
</picture>
<div class="mg-modal__actions">
  <a
    [iconRight]="true"
    [icon]="'mg-icon-link'"
    appearance="basic"
    href="https://seller.wildberries.ru/auth-services"
    iconSize="16"
    mgButton
    mode="primary"
    target="_blank"
  >
    <span class="d-none d-md-block">
      Ознакомиться в личном кабинете продавца
    </span>
    <span class="d-block d-md-none">
      В личный кабинет продавца
    </span>
  </a>
</div>
