import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DialogModule, DialogRef } from '@angular/cdk/dialog';
import { ButtonModule } from '@marketguru/ui-kit-components/components/button';

@Component({
  selector: 'app-authorized-button-modal',
  templateUrl: './authorized-button-modal.component.html',
  styleUrls: ['./authorized-button-modal.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [DialogModule, ButtonModule]
})
export class AuthorizedButtonModalComponent {
  constructor(public dialogRef: DialogRef) {}
}
