import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Dialog, DialogModule } from '@angular/cdk/dialog';
import { AuthorizedButtonModalComponent } from './authorized-button-modal/authorized-button-modal.component';

@Component({
  selector: 'app-authorized-button',
  templateUrl: './authorized-button.component.html',
  styleUrls: ['./authorized-button.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [DialogModule, AuthorizedButtonModalComponent],
  host: {
    '(click)': 'openModal()'
  }
})
export class AuthorizedButtonComponent {
  constructor(private _dialog: Dialog) {}

  openModal(): void {
    this._dialog.open(AuthorizedButtonModalComponent, {
      panelClass: ['mg-modal', 'authorized-button-modal']
    });
  }
}
